<template>
  <div class="order-summery-card">
    <div class="text-center lg:mt-0 mt-12 text-bluePrimary pt-6 lora-bold">
      {{ content.yourOrderSummery }}
    </div>
    <hr style="color: #37328b22" class="my-4 mx-4" />
    <div class="flex justify-between px-4 xl:px-6">
      <div class="col-span-2 lora-bold">
        {{ content.itemSpecifications }}
      </div>
      <div class="col-span-1 lora-bold text-align-end">
        {{ content.cost }}
      </div>
    </div>
    <div class="flex justify-between px-4 xl:px-6 mt-4">
      <div class="col-span-2 me-4 text-start">
        {{ selectedPackage?.title }}
      </div>
      <!-- <div class="col-span-1 whitespace-nowrap text-align-end">
        {{ plan.price }} {{ content.iqd }}
      </div> -->
    </div>
    <div class="flex justify-between px-4 xl:px-6 mt-4">
      <div class="col-span-2 me-4 text-start">
        {{ content.months }}: {{ plan?.months }}
      </div>
      <div class="col-span-1 whitespace-nowrap text-align-end">
        {{ format(plan?.price) }} {{ content.iqd }}
      </div>
    </div>
    <div class="flex justify-between px-4 xl:px-6 mt-4">
      <div class="col-span-2 me-4 text-start">
        {{ content.gift }}: {{ gift ? content.yes : content.no }}
      </div>
    </div>
    <hr style="color: #37328b22" class="my-4 mx-4" />
    <div class="grid grid-cols-5 px-4 xl:px-10 mt-4">
      <div class="col-span-1"></div>
      <div class="col-span-2 text-align-end">{{ content.subtotal }}:</div>
      <div class="col-span-2 text-align-end">
        {{ format(plan?.price * plan.months) }} {{ content.iqd }}
      </div>
    </div>
    <div class="grid grid-cols-5 px-4 xl:px-10 mt-2">
      <div class="col-span-1"></div>
      <div class="col-span-2 text-align-end whitespace-nowrap">
        {{ content.deliveryCost }}:
      </div>
      <div class="col-span-2 text-align-end">
        {{ format(delivery) }} {{ content.iqd }}
      </div>
    </div>
    <div class="grid grid-cols-5 px-4 xl:px-10 mt-4 text-size-20">
      <div class="col-span-1"></div>
      <div class="col-span-2 whitespace-nowrap">{{ content.total }}:</div>
      <div class="col-span-2 text-align-end whitespace-nowrap">
        {{ format(total) }} {{ content.iqd }}
      </div>
    </div>
    <div class="mx-3 my-4 py-4">
      <button
        v-if="buttonDisabled"
        class="w-full header-btn bg-cancelled bg-opacity-10 text-cancelled border-cancelled border-1 lora-bold whitespace-nowrap duration-300 cursor-default"
      >
        {{ content.submitOrder }}
      </button>
      <button
        v-else
        class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-bluePrimary duration-300 w-full hover:text-bluePrimary hover:bg-blueSecondary"
        @click="submitOrder"
      >
        {{ content.submitOrder }}
      </button>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  inject: ["content"],
  props: ["delivery", "buttonDisabled"],
  emits: ["submitOrder"],
  computed: {
    selectedPackage() {
      // console.log(
      //   this.$store.getters["subscription/package"](this.$route.query.p)
      // );
      return this.$store.getters["subscription/package"](this.$route.query.p);
    },
    plan() {
      return this.selectedPackage?.prices.filter(
        (plan) => plan.id == this.$route.query.m
      )[0];
    },
    total() {
      return this.delivery
        ? parseFloat(this.plan?.price * this.plan.months) + parseFloat(this.delivery)
        : this.plan?.price;
    },
    gift() {
      return this.$route.query.g == 1;
    },
  },
  methods: {
    submitOrder() {
      this.$emit("submitOrder");
    },
    format(number) {
      return format(number);
    },
  },
};
</script>
